<template>
    <div :class="CSSWrapperClasses">
        <slot name="title"></slot>
        <div class="personFeedBlock" :class="CSSClasses">
            <PersonBlock
                v-for="(person, index) in displayPeople"
                :person="person"
                :key="index"
                :size="personSizeVariant"
                :personNameOverride="getPersonFullName(person)"
                :hideDescription="hideDescriptions"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    blockTitle: {
        type: String,
    },
    people: {
        type: Object,
    },
    additionalPeople: {
        type: Object,
    },
    excludedPeople: {
        type: Object,
    },
    size: {
        ...sizes,
    },
    hideDescriptions: {
        type: Boolean,
    },
    theme: {
        type: String,
        default: 'light',
        validator: (value) => {
            return ['dark', 'light'].includes(value);
        },
    },
});

const CSSWrapperClasses = computed(() => ['personFeedBlock-wrapper', `theme-${props.theme}`]);

const CSSClasses = computed(() => [
    `size-${props.size}`,
    props.people?.length < 3 ? `col-${props.people?.length}` : 'col-3',
]);

const personSizeVariant = computed(() => {
    if (props.size === '100') {
        switch (props.people?.length) {
            case 1:
                return '100';
            case 2:
                return '50';
            default:
                return '33';
        }
    }
    return '50';
});

const displayPeople = computed(() => {
    return props.additionalPeople || props.people;
});
</script>

<style lang="scss" scoped>
.personFeedBlock-wrapper {
    .title {
        text-align: center;
        margin-bottom: vertical-space(2);
    }
}

.size-100 {
    &:not(.col-1) {
        display: grid;
        gap: var(--col-gutter);
    }

    &.col-2 {
        @include responsive(
            grid-template-columns,
            (
                default: repeat(2, 1fr),
                phone: repeat(1, 1fr),
            )
        );
    }

    &.col-3 {
        @include responsive(
            grid-template-columns,
            (
                default: repeat(3, 1fr),
                tablet: repeat(2, 1fr),
                phone: repeat(1, 1fr),
            )
        );
    }
}

.size-50 {
    &:not(.col-1) {
        display: grid;
        gap: var(--col-gutter);
    }

    &.col-2 {
        @include responsive(
            grid-template-columns,
            (
                default: repeat(2, 1fr),
                phone: repeat(1, 1fr),
            )
        );
    }

    &.col-3 {
        @include responsive(
            grid-template-columns,
            (
                default: repeat(3, 1fr),
                tablet: repeat(2, 1fr),
                phone: repeat(1, 1fr),
            )
        );
    }
}

.theme-light {
    .title {
        color: palette(text);
    }
}

.theme-dark {
    background: color(blue, dark);

    .title,
    .dek {
        color: color(blanc, default, 0.8);
    }

    .personTitle {
        color: color(blanc, default, 0.6);
    }
}

@include media-query(phone) {
    :deep(.person-details .rich-text) {
        display: none;
    }
}
</style>
